import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="How to add subtitles to a video"
          description="Take your content to the next level by adding subtitles to videos. This simple strategy can boost your digital marketing strategy in powerful ways."
        />

        <article>
          <h1>How To Add Subtitles To a Video?</h1>

          <p>
            In a world where everyone is constantly connected to some kind of device, videos is
            one of the best ways to interact your customers. If you're determined to make a lasting impression on each of your viewers,
            it's crucial to <b>include subtitles in your videos.</b>
          </p>


          <h2>Adding Subtitles</h2>

          <ol>
            <li id='step1'>
              <strong>Transcribe the audio to text</strong>
              <p>Create a <code>.srt</code> (subtitle file) that contains the text.</p>
            </li>
            <li id='step2'>
              <strong>Setup FFMPEG</strong>
              <p>Download and Install <a href='https://ffmpeg.org/'>FFMPEG</a>, a free open source tool that will burn in the subtitles into the video file.</p>
            </li>
            <li id='step3'>
              <strong>Add the subtitles to the video</strong>
              <p>Use ffmpeg to run:
              <pre>ffmpeg -i input.mp4 -i subtitles.srt -c copy -c:s mov_text output.mp4
              </pre></p>
            </li>
          </ol>


          <script type="application/ld+json">{`
                            {
                                "@context": "http://schema.org",
                                "@type": "HowTo",
                                "name": "How To Add Subtitles To a Video?",
                                "description": "Steps to take your content to the next level by adding subtitles to videos.",
                                "image": {
                                "@type": "ImageObject",
                                "url": "https://echowave.io/images/audio-convert.jpg",
                                "height": "491",
                                "width": "900"
                            },
                                "estimatedCost": {
                                "@type": "MonetaryAmount",
                                "currency": "USD",
                                "value": "0"
                            },
                                "tool": [
                            {
                                "@type": "HowToTool",
                                "name": "Facebook Account"
                            }, {
                                "@type": "HowToTool",
                                "name": "FFMPEG"
                            }, {
                                "@type": "HowToTool",
                                "name": "PC or Mobile Phone"
                            }
                                ],
                                "step": [
                                {
                                    "@type": "HowToStep",
                                    "name": "Convert the audio to text",
                                    "url": "https://echowave.io/how-to-add-subtitles-to-a-video/#step1",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Create a SRT file that contains the subtitles."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-1.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Setup FFMPEG",
                                    "url": "https://echowave.io/how-to-add-subtitles-to-a-video/#step2",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Download and Install FFMPEG, a free open source tool that will burn in the subtitles into the video file."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-2.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Add the subtitles to the video",
                                    "url": "https://echowave.io/how-to-add-subtitles-to-a-video/#step3",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Burn in the Subtitles to the video using: ffmpeg -i input.mp4 -i subtitles.srt -c copy -c:s mov_text output.mp4"
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-3.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                }
                                ],
                                "totalTime": "PT5M"
                            }`}
          </script>


          <section>
            <GetStartedOrChat cta="Create videos with EchoWave" />
            <br />
          </section>


          <h2>Why add subtitles to videos?</h2>
          <h3>Increased Accessibility</h3>
          <p>
            Approximately{' '}
            <a
              data-mce-href="https://www.nidcd.nih.gov/health/statistics/quick-statistics-hearing"
              href="https://www.nidcd.nih.gov/health/statistics/quick-statistics-hearing"
            >
              15 percent of American adults
            </a>{' '}
            are living with some kind of hearing impairment, according to the National Institutes of Health. If any of
            these 37 million viewers stumble upon your website and see that your videos lack subtitles, they'll likely
            head over to a different site that properly addresses their needs.
          </p>

          <p>
            If you make the simple decision to incorporate subtitles into your videos, you'll allow all of your viewers
            to have a more accessible experience on your site. This also gives you the chance to attract a larger
            customer base.
          </p>

          <h3>Larger Demographic</h3>
          <p>
            To expand your target audience, be sure to take other cultures into account. Viewers who speak a different
            language or have trouble understanding English will benefit immensely from subtitles. Take some time to
            research the most commonly spoken languages worldwide to make your captions as effective as possible.
          </p>
          <h3>Enhanced SEO Strategy</h3>
          <p>
            Believe it or not, adding subtitles to videos can even boost your SEO strategy. On one hand, viewers are
            likely to spend a longer time watching your video if they have to follow along with captions. In addition,
            subtitles give search engines the chance to{' '}
            <a data-mce-href="https://ahrefs.com/blog/google-index/" href="https://ahrefs.com/blog/google-index/">
              index the keywords
            </a>{' '}
            in your text, which may help you rank higher on Google or another search giant.
          </p>
          <h3>Greater Convenience</h3>
          <p>
            The benefits of subtitles don't just apply to non-English speakers or those who are hard of hearing. Videos
            with subtitles also offer a greater sense of convenience to all types of viewers. Whether they can't turn on
            the volume for some reason or prefer to read along as they watch, customers have various reasons for wanting
            captions included in their videos.
          </p>
          <h3>Stronger Message</h3>
          <p>
            When done correctly, subtitles can reinforce the message of your video by giving viewers a compelling visual
            component. Your audience is more likely to retain the information in your video when it's presented to them
            through text.
          </p>
          <p>
            In addition, adding subtitles to your content makes it easier for others to understand what the main concept
            of your video is. When the dialogue and narration are clearly spelled out for your audience, they'll have an
            easier time absorbing your message.
          </p>
          <p>
            Plus, to tie into the increased SEO value of your video, your content is also more likely to reach more
            viewers if you use subtitles. This is because customers who are searching for the specific message of your
            video will be more likely to stumble upon it if you spell it out with captions.
          </p>
          <h3>More Memorable Brand</h3>
          <p>
            The main goal of your videos is to give your customers a reason to think of you in the future. When you add
            subtitles, you give your viewers the chance to connect with your content on a more meaningful level. Once
            they've internalized your message, your customers will be more motivated to think of your company later on
            and possibly purchase your products or services.
          </p>
          <h3>Increased Versatility</h3>
          <p>
            Without subtitles, your viewers can only watch your videos in settings where doing so is appropriate. This
            means that office meetings, class sessions and family dinners are out of the question. However, by adding
            subtitles to videos, you can let your viewers secretly enjoy one of your creations in all kinds of
            situations without anyone knowing about it. This instantly makes your videos more versatile than they'd be
            without subtitles.
          </p>
          <h3>Fewer Incomplete Views</h3>
          <p>
            If you've noticed that a good chunk of your audience starts watching your videos only to abandon them
            seconds later, you probably have a lack of captioning to blame. Not only do subtitles keep your customers
            engaged for a longer time, but they also prevent them from moving on before they even finish watching the
            entire video.
          </p>
          <h3>Heightened Interest</h3>
          <p>
            Adding subtitles to videos isn't just about keeping your viewers watching for a longer time. When you
            enhance your content with subtitles, you're also more likely to attract your viewers' interest on a deeper
            level. Captions help give your videos another element for your audience to focus on, and this means they'll
            be more inclined to stay interested throughout the duration of the clip.
          </p>
          <h3>Improved Brand Reputation</h3>
          <p>
            The amount of work you put into forming the perfect video speaks volumes about your company. If your
            customers see that you simply threw together an uninspired, low-budget clip, there's a good chance they
            won't be coming back for more. On the other hand, a well-designed video shows your audience that you care
            about your craft and that you spent a lot of time making sure that the final product was exactly what you
            wanted.
          </p>
          <p>
            Incorporating subtitles into your content is an easy way to demonstrate a genuine commitment to your
            business. When you send the message that you care about what your company stands for, you'll be more likely
            to improve your reputation and retain more loyal customers in the long run.
          </p>
          <h2>Go the Extra Mile by Adding Subtitles to Videos</h2>
          <p>
            Captioning your videos might not seem like a significant way to boost your digital marketing strategy.
            However, you'd be surprised at what this simple strategy can do for the success of your business. If you're
            ready to take your online videos to the next level, don't hesitate to look for the{' '}
            <a
              data-mce-href="https://echowave.io/audio-waveform-video-generator/"
              href="https://echowave.io/audio-waveform-video-generator/"
            >
              professional assistance
            </a>{' '}
            that you need. You'll let your content reach a much wider audience once you improve them with clear
            subtitles that are easy for everyone to understand.
          </p>
        </article>
      </Layout>
    )
  }
}

export default Page
